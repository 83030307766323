export const APP_VERSION = '1.1.5';
export const API_URL_COINCAP = 'https://api.coincap.io/v2';
export const API_URL_SOCKET = 'wss://ws.coincap.io';
export const APP_DIVIDER = '|';
export const APP_TITLE_PREFFIX = 'CryptoLine, Prices, Live Charts, and Market Data';
export const APP_NAME = 'CryptoLine';
export const APP_MAIL = 'info@maucariapa.com';
export const CHANGELLY_REF_ID = 'cJ2h8rWp4UbDgKsz'; //PAST YOUR CHANGELLY REFERAL ID
export const CHANGELLY_EXANGE_URL = 'https://changelly.com';
export const CHANGELLY_EXANGE_AMOUNT = 0.1;
export const COINS_LIMIT = 1000; // Max size 2000 per request
export const SYMBOL_EMPTY = '–';
